import React, { useState, useEffect } from 'react';
import { Grid, Menu, Segment } from 'semantic-ui-react';
import "./index.css"
import CodeBlock from '../../components/CodeBlock';

const DocsPage = () => {
  const [activeItem, setActiveItem] = useState('home');
  const codeSnippets = {
    javascript: `const greet = () => console.log("Hello, World!");`,
    python: `def greet():\n    print("Hello, World!")`
  };
  const handleItemClick = (name) => setActiveItem(name);

  useEffect(() => {
    const handleScroll = () => {
      // 根据滚动位置更新 activeItem
      // For example: 如果滚动到了特定部分，就Settings setActiveItem('particularSection')
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Grid>
      <Grid.Column width={4}>
        <Menu fluid vertical tabular>
          <Menu.Item
            name='section1'
            active={activeItem === 'section1'}
            onClick={() => handleItemClick('section1')}
          />
          <Menu.Item
            name='section2'
            active={activeItem === 'section2'}
            onClick={() => handleItemClick('section2')}
          />
          {/* 更多导航项 */}
        </Menu>
      </Grid.Column>

      <Grid.Column stretched width={10}>
        <Segment className="no-border-segment">
          <h1 className="text-center">Docs</h1>
          <h3 className="text-center" style={{marginBottom: "1.5rem"}}>
            The future will bring us hundreds of language models and dozens of providers for each.
            <em>How will you choose the best?</em>
          </h3>
          <p><strong className="font-strong">Benefit from the race to the bottom</strong>. OpenRouter finds the lowest price for each model across dozens of providers. You can also let users pay for their own models via OAuth PKCE.</p >
          <p><strong className="font-strong">Standardized API</strong>. No need to change your code when switching between models or providers.</p >
          <p><strong className="font-strong">The best models will be used the most</strong>. Evals are flawed. Instead, compare models by how often they're used, and soon, for which purposes. Chat with multiple at once in the Playground.</p >
        </Segment>
        <Segment className="no-border-segment">
          <hr className="my-hr"/>
          <h2 className="text-center">Quick Start</h2>
          <CodeBlock
            codeSnippets={codeSnippets}
            languages={['javascript', 'python']}
          />
          <p>You can also use OpenRouter with OpenAI's client API:</p>
          <CodeBlock
            codeSnippets={codeSnippets}
            languages={['javascript', 'python']}
          />
        </Segment>
        <Segment className="no-border-segment">
          <hr className="my-hr"/>
          <h2 className="text-center">Supported Models</h2>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default DocsPage;