import React, { useEffect, useState } from 'react';

import { Container, Segment } from 'semantic-ui-react';
import { getFooterHTML, getSystemName } from '../helpers';
import { Link } from 'react-router-dom';
import OtherTools from '../pages/OtherTools';

const Footer = () => {
  const systemName = getSystemName();
  const [footer, setFooter] = useState(getFooterHTML());
  let remainCheckTimes = 5;

  const loadFooter = () => {
    let footer_html = localStorage.getItem('footer_html');
    if (footer_html) {
      setFooter(footer_html);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (remainCheckTimes <= 0) {
        clearInterval(timer);
        return;
      }
      remainCheckTimes--;
      loadFooter();
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Segment vertical>
      <Container textAlign='center'>
        {footer ? (
          <div
            className='custom-footer'
            dangerouslySetInnerHTML={{ __html: footer }}
          ></div>
        ) : (
          <div className='custom-footer'>
            <span style={{fontSize: "14px", fontWeight: "700", marginLeft: "6%"}}> Copyright © Inferkit AI  </span>
            <div style={{marginRight: "8%"}}>
            <Link style={{color: '#72727b', fontSize: "14px", marginRight: "8px"}} to="/tools">Other Tools</Link>
            <Link style={{color: '#72727b', fontSize: "14px"}} to="/privacy">Privacy</Link>
            <Link style={{ marginLeft: '1em', color: '#72727b', fontSize: "14px" }} to="/userprivacy">Terms</Link>
            </div>
          </div>
        )}
      </Container>
    </Segment>
  );
};

export default Footer;
