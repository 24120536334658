import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Form, Grid, Header, Image, Message, Modal, Segment } from 'semantic-ui-react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../context/User';
import { API, getLogo, showError, showSuccess, showWarning } from '../helpers';
import { onGitHubOAuthClicked } from './utils';

const loginButtonStyle = {
  padding: '10px',
  marginBottom: '16px',
  fontSize: '18px',
  backgroundColor: '#333', // Grey-black background
  border: 'none', // Remove default border
  color: '#fff', // Text color
  borderRadius: '8px', // Rounded corners
  // height: '42px',
  // width: '90px', // Slightly larger width
  cursor: 'pointer', // Changes cursor to a hand symbol on hover
};

const noBorder = {
  border: 'none',
  boxShadow: 'none'
}

const LoginForm = () => {
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    wechat_verification_code: ''
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const { username, password } = inputs;
  const [userState, userDispatch] = useContext(UserContext);
  let navigate = useNavigate();
  const [status, setStatus] = useState({});
  const logo = getLogo();

  useEffect(() => {
    if (searchParams.get('expired')) {
      showError('Please login');
    }
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      setStatus(status);
    }
  }, []);

  const [showWeChatLoginModal, setShowWeChatLoginModal] = useState(false);

  const onWeChatLoginClicked = () => {
    setShowWeChatLoginModal(true);
  };

  const onSubmitWeChatVerificationCode = async () => {
    const res = await API.get(
      `/api/oauth/wechat?code=${inputs.wechat_verification_code}`
    );
    const { success, message, data } = res.data;
    if (success) {
      userDispatch({ type: 'login', payload: data });
      localStorage.setItem('user', JSON.stringify(data));
      navigate('/');
      showSuccess('Login success!');
      setShowWeChatLoginModal(false);
    } else {
      showError(message);
    }
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    setSubmitted(true);
    if (username && password) {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        userDispatch({ type: 'login', payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        if (username === 'root' && password === '123456') {
          navigate('/user/edit');
          showSuccess('Login success!');
          showWarning('请立刻修改DefaultPassword！');
        } else {
          navigate('/topup');
          showSuccess('Login success!');
        }
      } else {
        showError(message);
      }
    }
  }

  return (
    <Grid textAlign='center' style={{ marginTop: '48px' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={logo} size={"mini"} />
        <Header as='h2' color='' textAlign='left' >
          Welcome Inferkit
        </Header>
        <p style={{textAlign: 'left'}}>
          Sign in with your email
        </p>
        <Form textAlign='left' size='large' >
          <Segment textAlign='left'>
            <Form.Input
              fluid
              // icon='user'
              // iconPosition='left'
              // placeholder='邮箱'
              label="Email"
              name='username'
              value={username}
              onChange={handleChange}
            />
            <Form.Input
              fluid
              // icon='lock'
              // iconPosition='left'
              // placeholder='Password'
              label="Password"
              name='password'
              type='password'
              value={password}
              onChange={handleChange}
            />
            <p style={{textAlign: 'center', fontSize: "13px", marginTop: "16px", marginBottom: "16px"}}>
              { "I agree to the "}
              <Link style={{ color: '#72727b', fontSize: "14px" }} to="/userprivacy">Terms of Service</Link>
              { " and " }
              <Link style={{color: '#72727b', fontSize: "14px"}} to="/privacy">Privacy Policy</Link>
            </p>
            <Button style={loginButtonStyle} fluid size='large' onClick={handleSubmit}>
              Login
            </Button>
            <p style={{fontSize: "13px"}}>
              Forget password？
              <Link to='/reset' style={{color: '#72727b', fontWeight: "600"}}>
                Reset
              </Link>
              ； No account？
              <Link to='/register' style={{color: '#72727b', fontWeight: "600"}}>
                Sign Up
              </Link>
            </p>
          </Segment>
        </Form>

        {status.github_oauth || status.wechat_login ? (
          <>
            <Divider horizontal>Or</Divider>
            {status.github_oauth ? (
              <Button
                circular
                color='black'
                icon='github'
                onClick={() => onGitHubOAuthClicked(status.github_client_id)}
              />
            ) : (
              <></>
            )}
            {status.wechat_login ? (
              <Button
                circular
                color='green'
                icon='wechat'
                onClick={onWeChatLoginClicked}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <Modal
          onClose={() => setShowWeChatLoginModal(false)}
          onOpen={() => setShowWeChatLoginModal(true)}
          open={showWeChatLoginModal}
          size={'mini'}
        >
          <Modal.Content>
            <Modal.Description>
              <Image src={status.wechat_qrcode} fluid />
              <div style={{ textAlign: 'center' }}>
                <p>
                  Scan the QR code of WeChat to follow the official account, enter "verification code" to get the verification code (valid within three minutes)
                </p>
              </div>
              <Form size='large'>
                <Form.Input
                  fluid
                  placeholder='Verification Code'
                  name='wechat_verification_code'
                  value={inputs.wechat_verification_code}
                  onChange={handleChange}
                />
                <Button
                  color=''
                  fluid
                  size='large'
                  onClick={onSubmitWeChatVerificationCode}
                >
                  Login
                </Button>
              </Form>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;