import React, { useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'copy-to-clipboard';

const buttonStyle = {
  border: 'none', // 去掉边框
  backgroundColor: '#fdfdfd', // SettingsDefault背景颜色
  color: '#72727b',
  fontWeight: 500,
  fontSize: '0.8em',
  padding: '10px', // 添加一些内边距
  cursor: 'pointer' // 将鼠标光标变为手指形状
};

const copyButtonStyle = {
  position: 'absolute', /* 按钮使用绝对定位 */
  right: 0, /* 距离右侧的距离 */
  border: 'none', // 去掉边框
  backgroundColor: '#fdfdfd', // SettingsDefault背景颜色
  color: '#72727b',
  fontWeight: 500,
  fontSize: '0.8em',
  padding: '10px', // 添加一些内边距
  cursor: 'pointer' // 将鼠标光标变为手指形状
}

const buttonStyleActive = {
  ...buttonStyle,
  backgroundColor: '#f0f0f3' // 点击时的背景颜色
};

const CodeBlock = ({ codeSnippets, languages }) => {
  const [language, setLanguage] = useState(languages[0]);
  const [activeButton, setActiveButton] = useState('');
  const [copyButtonText, setCopyButtonText] = useState('Copy code');
  let timeoutId;

  useEffect(() => {
    return () => {
      // 清理函数
      clearTimeout(timeoutId);
    };
  }, []);


  const handleLanguageClick = (lang) => {
    setLanguage(lang);
    setActiveButton(lang);
  };

  const handleCopy = () => {
    copy(codeSnippets[language]);
    setCopyButtonText('√ Copied!');

    timeoutId = setTimeout(() => {
      setCopyButtonText('Copy code');
    }, 3000); // 3秒后将文案改回 "Copy"
  };

  const customStyle = {
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#f8f8f8', // 浅灰色背景
    // 你可以在这里添加其他样式
  };


  return (
    <div style={{position: 'relative', marginBottom: '1rem'}}>
      {languages.map(lang => (
        <button
          key={lang}
          onClick={() => handleLanguageClick(lang)}
          style={activeButton === lang ? buttonStyleActive : buttonStyle}
        >
          {lang}
        </button>
      ))}
      <button
        onClick={handleCopy}
        style={activeButton === 'copy' ? buttonStyleActive : copyButtonStyle}
      >
        {copyButtonText}
      </button>

      <SyntaxHighlighter language={language} style={prism} customStyle={customStyle}>
        {codeSnippets[language]}
      </SyntaxHighlighter>
    </div>
  );
};

export default CodeBlock;
