import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, GridColumn, GridRow, Header, Image, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import './index.css';
import { Link } from 'react-router-dom';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userEmail, setUserEmail] = useState('');



  const priceButtonStyle = {
    padding: '10px',
    fontSize: '20px',
    backgroundColor: '#333', // Grey-black background
    border: 'none', // Remove default border
    color: '#fff', // Text color
    borderRadius: '8px', // Rounded corners
    height: '42px',
    width: '96px', // Slightly larger width
    cursor: 'pointer', // Changes cursor to a hand symbol on hover
  };

  const confirmButtonStyle = {
    marginLeft: '16px',
    fontSize: '16px',
    textAlign: 'center',
    backgroundColor: '#545454', // Grey-black background
    border: 'none', // Remove default border
    color: '#fff', // Text color
    borderRadius: '8px', // Rounded corners
    height: '32px',
    width: '100px', // Slightly larger width
    cursor: 'pointer', // Changes cursor to a hand symbol on hover
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  };

  const topUp = async () => {
    if (redemptionCode === '') {
      showInfo('Please input topup code！')
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('success！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('request fail');
    } finally {
      setIsSubmitting(false);
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('Super administrator has not set the recharge link!');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const getUserQuota = async ()=>{
    let res  = await API.get(`/api/user/self`);
    const {success, message, data} = res.data;
    if (success) {
      setUserQuota(data.quota);
      setUserEmail(data.email)
    } else {
      showError(message);
    }
  }

  const getCheckoutUrl = async (email, priceId) => {
    const res = await API.post(`/stripe_checkout`, {
      email: email,
      price: priceId
    });
    const { success, message, data } = res.data;
    if (success) {
      if (data) {
        window.location.href = data; // 重定向到支付页面
      }
    } else {
      showError(message);
      return ""
    }
  }

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();

    // let user = localStorage.getItem('user');
    // setUserEmail(user.email)
  }, []);

  return (
    <Segment style={{ textAlign: "center" }}>
      {/*<Header as='h3'>Recharge Quota</Header>*/}
      <Grid columns={1} stackable>
        <GridRow>
          <GridColumn>
            <GridRow>
              <div style={{ fontSize: "28px", fontWeight: "600", padding: "6px",}}>
                Credit
              </div>
            </GridRow>
            <GridRow>
              <div>
                <Statistic size='small' style={{padding: "12px",}}>
                  <Statistic.Value style={{fontWeight: "600"}}>{renderQuota(userQuota)}</Statistic.Value>
                </Statistic>
              </div>
            </GridRow>
            <GridRow>
              <div style={{padding: "6px"}}>
                <Button style={{
                  background: 'none',
                  border: 'none',
                  boxShadow: 'none',
                  padding: "6px",
                  fontSize: "16px",
                  textDecoration: 'underline' // 添加下划线
                }} as={Link} to="/log" basic>View Usage</Button>
              </div>
            </GridRow>
          </GridColumn>
        </GridRow>

        <GridRow>
          <GridColumn>
            <div style={{ fontSize: "24px", fontWeight: "900",
              textAlign: "center"}}>
              Add Credit
            </div>
          </GridColumn>
        </GridRow>
        {/*<GridRow>*/}
        {/*  <GridColumn>*/}
        {/*    <GridRow>*/}
        {/*      <Grid columns={3} stackable>*/}
        {/*        <Grid.Row style = {{paddingLeft: "28%", paddingRight: "28%", paddingBottom: "8px"}}>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "10-usd")}>$10</Button>*/}
        {/*          </Grid.Column>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "50-usd")}>$50</Button>*/}
        {/*          </Grid.Column>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "100-usd")}>$100</Button>*/}
        {/*          </Grid.Column>*/}
        {/*        </Grid.Row>*/}
        {/*        <Grid.Row style = {{paddingLeft: "28%", paddingRight: "28%"}}>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "500-usd")}>$500</Button>*/}
        {/*          </Grid.Column>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "1000-usd")}>$1000</Button>*/}
        {/*          </Grid.Column>*/}
        {/*          <Grid.Column>*/}
        {/*            <Button style={priceButtonStyle} as={Link} onClick={() => getCheckoutUrl(userEmail, "5000-usd")}>$5000</Button>*/}
        {/*          </Grid.Column>*/}
        {/*        </Grid.Row>*/}
        {/*      </Grid>*/}
        {/*    </GridRow>*/}
        {/*  </GridColumn>*/}
        {/*</GridRow>*/}

        {/*<GridRow>*/}
        {/*  <Grid.Column>*/}
        {/*    <div style={{padding: "6px"}}>*/}
        {/*      <Button style={{*/}
        {/*        background: 'none',*/}
        {/*        border: 'none',*/}
        {/*        boxShadow: 'none',*/}
        {/*        padding: "6px",*/}
        {/*        fontSize: "14px",*/}
        {/*        textDecoration: 'underline' // 添加下划线*/}
        {/*      }} as={Link} onClick={() => getCheckoutUrl(userEmail, "100-hkd")} basic>Pay using Alipay or Wechat</Button>*/}
        {/*    </div>*/}
        {/*  </Grid.Column>*/}
        {/*</GridRow>*/}

        <GridRow>
          <GridColumn>
            <div  style={{paddingTop: "40px", paddingLeft: "30%", paddingRight: "26%"}}>
              <Form>
                <Form.Group inline style={{ alignItems: 'center' }} unstackable>
                  <Form.Input
                    placeholder='                             Redemption Code'
                    input={{ style: {width: "300px", borderRadius: '8px' } }}
                    name='redemptionCode'
                    value={redemptionCode}
                    onChange={(e) => {
                      setRedemptionCode(e.target.value);
                    }}
                  />
                  <Button style={confirmButtonStyle} onClick={topUp} disabled={isSubmitting}>
                    {isSubmitting ? 'Confirm...' : 'Confirm'}
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </GridColumn>
        </GridRow>

      </Grid>
    </Segment>
  );
};

export default TopUp;