import React from 'react';
import { Segment, Header, GridRow, GridColumn, Statistic, Button, Grid } from 'semantic-ui-react';
import TokensTable from '../../components/TokensTable';
import { renderQuota } from '../../helpers/render';
import { Link } from 'react-router-dom';

const keyButtonStyle = {
  padding: '10px',
  fontSize: '16px',
  backgroundColor: '#333', // Grey-black background
  border: 'none', // Remove default border
  color: '#fff', // Text color
  borderRadius: '8px', // Rounded corners
  height: '40px',
  width: '180px', // Slightly larger width
  cursor: 'pointer', // Changes cursor to a hand symbol on hover
};

const Token = () => (
  <Segment style={{ textAlign: "center" }}>
    <Grid columns={1} stackable>
      <GridRow>
        <GridColumn>
          <GridRow>
            <h1 style={{ fontSize: "18px", fontWeight: "900", padding: "6px",}}>
              API Keys
            </h1>
          </GridRow>
          <GridRow>
            <div style={{ fontSize: "13px",  padding: "6px", color: "#555"}}>
              Manage your keys or create new ones
            </div>
          </GridRow>
          <GridRow>
            <div style={{padding: "6px"}}>
              <Button style={keyButtonStyle} as={Link} to='/token/add'>Create Keys</Button>
            </div>
          </GridRow>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn>
          <TokensTable/>
        </GridColumn>
      </GridRow>
    </Grid>
  </Segment>
);

export default Token;