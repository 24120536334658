import "./index.css"
import { Segment } from 'semantic-ui-react';
import React from 'react';
const markDownText = `## Inferkit隐私政策

最后更新：2023-12-23

本隐私政策描述了Inferkit（以下简称“我们”）在您访问或使用我们的服务时，如何收集、使用及披露About您的信息。

1. 个人信息的收集
   我们收集并储存您选择与我们分享的个人识别信息，For example您的电子邮件地址或钱包地址。我们还收集与您使用我们的服务相关的信息，For example与Model请求相关联的Web应用程序URL。
2. User可以选择退出LogPrompt和完成情况的记录，这些用于改进匿名分析特性，For example分类。账户页面的分析部分可控制此偏好Settings。User还可以通过联系我们的support@inferkit.ai地址请求DeletePrompt和完成情况。
3. 信息的使用
   我们出于多种目的使用我们收集的信息，包括：
   (1) 建立和维护您的账户
   (2) 改进我们的服务
   (3) 计费目的
4. 信息共享与披露
   我们不会与第三方分享您的个人信息，除非是某些服务提供商，包括分析服务（如Google Analytics）和第三方AI服务提供商（如OpenAI和Azure）。这些第三方只能在必要时访问您的信息，且不允许将其用于其他目的。
5. User权利与选择
   您可以通过我们平台上的User界面更新可修改的个人信息。如果您希望请求Delete您的个人数据，请使用我们的support@inferkit.ai地址与我们联系。
6. Cookies与其他跟踪技术
   我们使用cookies来存储有关您的会话信息，以增强您在我们平台上的体验。使用我们的服务，即表示您同意我们使用这些方法。
7. 数据传输
   我们不会将您的个人数据进行国际传输。
8. 针对儿童的政策
   我们的服务不针对13岁以下的儿童，我们不会故意收集13岁以下儿童的个人信息。如果我们得知我们收集了13岁以下儿童的个人信息，我们将采取措施从我们的记录中Delete此类信息。
9. 隐私政策的更改
   我们可以随时修改本隐私政策，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。
10. 联系我们
    如果您对本隐私政策或我们的做法有任何疑问或担忧，请通过邮件联系我们。
11. 终止
    我们可以随时出于任何原因终止您使用服务的权利，包括违反服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。

终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。
`

const Privacy = () => {


  return (
    <div style={{marginLeft: "16%", marginRight:"16%"}}>
      <Segment className="no-border-segment">
        <h1 className="text-center">Inferkit Privacy Policy</h1>
        <p className="text-center">Last Updated: 2023-12-23</p>
        <p className="text-center" style={{marginLeft: "16%", marginRight:"16%"}}>This privacy policy describes how Inferkit ("we") collect, use, and disclose information about you when you access or use our services.</p>
        <h3>1. Collection of Personal Information</h3>
        <p>
          We collect and store personal identification information you choose to share with us, such as your email address or wallet address. We also collect information related to your use of our services, such as web application URLs associated with model requests.
        </p>
        <p>
          Users can choose to opt out of logging prompts and completion statuses, which are used for improving anonymous analytical features, such as categorization. This preference can be controlled in the analytics section of the account page. Users can also request to delete prompts and completion statuses by contacting us at support@inferkit.ai.
        </p>
        <h3>2. Use of Information</h3>
        <p>
          We use the information we collect for multiple purposes, including:
          <ol>
            <li>Establishing and maintaining your account</li>
            <li>Improving our services</li>
            <li>Billing purposes</li>
          </ol>
        </p>
        <h3>3. Sharing and Disclosure of Information</h3>
        <p>
          We do not share your personal information with third parties, except with certain service providers, including analytics services (such as Google Analytics) and third-party AI service providers (such as OpenAI and Azure). These third parties may only access your information when necessary and are not permitted to use it for other purposes.
        </p>
        <h3>4. User Rights and Choices</h3>
        <p>
          You can update modifiable personal information via the user interface on our platform. If you wish to request the deletion of your personal data, please contact us at support@inferkit.ai.
        </p>
        <h3>5. Cookies and Other Tracking Technologies</h3>
        <p>We use cookies to store information about your session to enhance your experience on our platform. By using our services, you agree to our use of these methods.</p>
        <h3>6. Data Transfer</h3>
        <p>We do not transfer your personal data internationally.</p>
        <h3>7. Policy for Children</h3>
        <p>Our services are not targeted at children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information from our records.</p>
        <h3>8. Changes to the Privacy Policy</h3>
        <p>We may modify this privacy policy at any time without further notice. Your continued use of our services indicates your acceptance and agreement to these changes.</p>
        <h3>9. Contact Us</h3>
        <p>If you have any questions or concerns about this privacy policy or our practices, please contact us by email.</p>
        <h3>10. Termination</h3>
        <p>We may terminate your right to use the services at any time for any reason, including a breach of service terms, effective upon notification to you. You may terminate your use of the services at any time by ceasing to use them.</p>
        <p>After termination, all rights and obligations will be extinguished, except for rights and obligations that by their nature should survive, including but not limited to ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution terms.</p>

      </Segment>
    </div>
  )
};

export default Privacy